import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import { Layout, Seo, Nav, VerticalSection } from "components/common"

import { useContentfulSection, useMeta, glassStyle, device } from "utils"

const ErrorPage = ({ data: { error } }) => {
  const {
    hero_400: { title, subtitle },
  } = useContentfulSection(error.sections)

  const meta = useMeta(error)

  return (
    <Layout hideFooter>
      <Seo {...meta} />
      <Nav />

      <Wrap>
        <VerticalSection
          className="messageBox"
          titleAs="h1"
          align="left"
          title={title}
          subtitle={subtitle}
        />
      </Wrap>
    </Layout>
  )
}

const Wrap = styled.div`
  flex: 1;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  --section-mobile-padding-top: var(--sp-mobile-nav);

  .messageBox {
    width: 100%;
    max-width: 1080px;
    margin: 0 auto;
    .tgContainer {
      ${glassStyle};
      padding: var(--section-mobile-padding);

      @media ${device.laptop} {
        padding: var(--sp-80);
      }

      .subtitle {
        white-space: break-spaces;

        a {
          color: var(--water);
        }
      }
    }
  }
`

export const query = graphql`
  {
    error: contentfulPage(pageId: { eq: "400" }) {
      ...Page
    }
  }
`

export default ErrorPage
